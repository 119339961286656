import type { SharingButton } from '@/legacy/components/SharingButtons/types'

export const SHARING_TITLE = 'Confira este conteúdo da Serasa'

export const SHARING_BUTTONS_MAP: Record<string, Omit<SharingButton, 'handleOnClick'>> = {
  whatsapp: {
    sharerUrl: `https://api.whatsapp.com/send?text=Olá! ${SHARING_TITLE}: `,
    buttonIcon: 'ghost-whatsapp',
    accessibleLabel: 'Compartilhar no WhatsApp',
  },
  facebook: {
    sharerUrl: 'https://www.facebook.com/sharer/sharer.php?u=',
    buttonIcon: 'ghost-facebook',
    accessibleLabel: 'Compartilhar no Facebook',
  },
  linkedin: {
    sharerUrl: 'https://www.linkedin.com/shareArticle/?mini=true&url=',
    buttonIcon: 'ghost-linkedin',
    accessibleLabel: 'Compartilhar no LinkedIn',
  },
  twitter: {
    sharerUrl: `https://twitter.com/share?text=${SHARING_TITLE}: &url=`,
    buttonIcon: 'ghost-twitter',
    accessibleLabel: 'Compartilhar no Twitter',
  },
  email: {
    sharerUrl: `mailto:?subject=Compartilhar conteúdo da Serasa&body=${SHARING_TITLE}: `,
    buttonIcon: 'ghost-email',
    accessibleLabel: 'Compartilhar por e-mail',
  },
  copyLink: {
    buttonIcon: 'ghost-link',
    accessibleLabel: 'Copiar o link desta página',
  },
} as const
