import { Builder } from '@builder.io/react'
import type { Input } from '@builder.io/sdk'
import dynamic from 'next/dynamic'

import { BLOCK_NAMES } from '@/legacy/blocks/blockNames'

import { hasButtonBuilderInputs } from '@/legacy/blocks/constants'

import { MIDDLE_BUTTON_BACKGROUND_VARIANTS } from './constants'

const eventInput: Input = {
  name: 'event',
  friendlyName: 'Evento GA',
  type: 'object',
  subFields: [
    {
      name: 'itemId',
      friendlyName: 'Custom item_id',
      type: 'string',
    },
  ],
}

Builder.registerComponent(
  dynamic<never>(() => import('.').then((mod) => mod.MiddleButtonBlock)),
  {
    name: BLOCK_NAMES.premium.middleButton,
    inputs: [
      ...hasButtonBuilderInputs,
      eventInput,
      {
        name: 'background',
        type: 'text',
        defaultValue: MIDDLE_BUTTON_BACKGROUND_VARIANTS.snowGray,
        enum: [
          { label: 'Branco/Cinza', value: MIDDLE_BUTTON_BACKGROUND_VARIANTS.snowGray },
          { label: 'Cinza/Branco', value: MIDDLE_BUTTON_BACKGROUND_VARIANTS.graySnow },
          { label: 'Cinza', value: MIDDLE_BUTTON_BACKGROUND_VARIANTS.gray },
          { label: 'Branco', value: MIDDLE_BUTTON_BACKGROUND_VARIANTS.snow },
        ],
      },
    ],
  },
)
