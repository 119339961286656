import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'

import { BLOCK_NAMES } from '@/legacy/blocks/blockNames'
import {
  heroSubtitleBuilderInput,
  heroTitleBuilderInput,
  idBuilderInput,
} from '@/legacy/blocks/constants'

import { hasCountdownBuilderInputs } from '../Countdown/constants'

Builder.registerComponent(
  dynamic<never>(() => import('.').then((mod) => mod.HeroBlock)),
  {
    name: BLOCK_NAMES.hero.default,
    inputs: [
      {
        ...heroTitleBuilderInput,
        required: true,
      },
      heroSubtitleBuilderInput,
      idBuilderInput,
      ...hasCountdownBuilderInputs,
    ],
  },
)
