import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'

import { BLOCK_NAMES } from '@/legacy/blocks/blockNames'
import { buttonAriaLabelBuilderInput, hasButtonBuilderInputs } from '@/legacy/blocks/constants'
import { builderInputsBlock } from '@/legacy/blocks/utils'
import { buttonModifierBuilderInput } from '@/legacy/components/Button/constants.register'
import {
  midiaTypeBuilderInput,
  imageBuilderInput,
} from '@/legacy/components/Media/constants.register'

import { LIST_LAYOUT, listLayoutBuilderInput } from './constants'

Builder.registerComponent(
  dynamic(() => import('.').then((mod) => mod.ListBlock) as never),
  {
    name: BLOCK_NAMES.list.partner,
    inputs: [
      ...builderInputsBlock(),
      {
        name: 'subtitleWeight',
        friendlyName: 'Subtítulo - Peso',
        type: 'string',
        defaultValue: 'bold',
        enum: ['regular', 'bold'],
      },
      ...hasButtonBuilderInputs,
      {
        ...buttonModifierBuilderInput,
        name: 'buttonModifier',
        showIf: (options) => options.get('hasButton'),
      },
      buttonAriaLabelBuilderInput,
      {
        name: 'buttonRounded',
        type: 'boolean',
        friendlyName: 'Botão - Arredondado',
        defaultValue: false,
      },
      {
        ...listLayoutBuilderInput,
        defaultValue: LIST_LAYOUT.partner,
      },
      {
        name: 'items',
        type: 'list',
        subFields: [
          {
            name: 'link',
            type: 'string',
            friendlyName: 'Link URL',
          },
          {
            name: 'midia',
            type: 'object',
            friendlyName: 'Mídia',
            subFields: [
              {
                ...midiaTypeBuilderInput,
                defaultValue: 'image',
                hideFromUI: true,
              },
              {
                ...imageBuilderInput,
                // @TODO BUG BUILDER - não aceita showIf em subFields
                showIf: undefined,
              },
            ],
          },
        ],
      },
    ],
  },
)
