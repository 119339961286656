import { useRouter } from 'next/router'

import { useEffect, useState } from 'react'

import { SHARING_TITLE } from '@/legacy/blocks/SharingButtons/constants'
import { copyToClipboard } from '@/legacy/core/utils/copyToClipboard'
import { getPageUrl } from '@/legacy/core/utils/dom/utils'

import { LAZY_ICONS_MAPPING } from '../Icon/icons'

import type { SharingButton } from './types'

export const DEFAULT_MESSAGE_TIMEOUT = 4000
export const NOTIFIER_MESSAGE = 'Link copiado com sucesso!'
export const SHARING_LABEL = 'Compartilhe o artigo'
export const LIST_LABEL = 'Botões para o compartilhamento deste conteúdo'

export const BACKGROUND_COLORS = {
  [LAZY_ICONS_MAPPING['ghost-whatsapp']]: '#377F6A',
  [LAZY_ICONS_MAPPING['ghost-linkedin']]: '#3375B0',
  [LAZY_ICONS_MAPPING['ghost-facebook']]: '#39519A',
  [LAZY_ICONS_MAPPING['ghost-twitter']]: '#4194D3',
  [LAZY_ICONS_MAPPING['ghost-email']]: '#A47D7E',
  [LAZY_ICONS_MAPPING['ghost-link']]: '#323850',
  default: '#F4F4F4',
} as const

export const NOTIFICATION_DEFAULT_PROPS = {
  message: NOTIFIER_MESSAGE,
  verticalPosition: 'top' as const,
  bottomSpacing: 'sm' as const,
}

export function useGenerateSharerUrl({ sharerUrl }: Pick<SharingButton, 'sharerUrl'>) {
  const { asPath } = useRouter()

  if (!sharerUrl) return

  const currentPageUrl = encodeURIComponent(process.env.NEXT_PUBLIC_HOST + asPath)
  const sharerUrlWithPageUrl = `${sharerUrl}${currentPageUrl}`

  return sharerUrlWithPageUrl
}

interface NavigatorShareProps {
  title: string
  isEnabled?: boolean
}

async function navigatorShare({ title }: NavigatorShareProps) {
  if (typeof navigator === 'undefined' || typeof navigator.share !== 'function') {
    return Promise.reject(new Error('Navigator share is unavailable.'))
  }

  const currentPageUrl = getPageUrl()
  const shareData = { url: currentPageUrl, title }

  await navigator.share(shareData)
}

export function useSharingButtons() {
  const [showMessageNotifier, setShowMessageNotifier] = useState(false)
  const currentPageUrl = getPageUrl()

  useEffect(() => {
    if (!showMessageNotifier) return

    const timeout = setTimeout(() => setShowMessageNotifier(false), DEFAULT_MESSAGE_TIMEOUT)
    return () => clearTimeout(timeout)
  }, [showMessageNotifier])

  const handleOnClick = () => navigatorShare({ title: SHARING_TITLE }).catch(() => handleCopy())

  const handleCopy = () => copyToClipboard(currentPageUrl)?.then(() => setShowMessageNotifier(true))

  return {
    showMessageNotifier,
    handleOnClick,
  }
}
