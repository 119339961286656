import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'

import { BLOCK_NAMES } from '@/legacy/blocks/blockNames'
import { titleBuilderInput } from '@/legacy/blocks/constants'
import { builderInputsBlock } from '@/legacy/blocks/utils'
import { midiaBuilderInput } from '@/legacy/components/Media/constants.register'

import { listLayoutBuilderInput, LIST_LAYOUT } from './constants'
import type { ListBlockProps } from './List'

const panelItemInputs = [
  {
    ...titleBuilderInput,
    required: true,
    defaultValue: {
      text: 'Título',
      tag: 'h4',
    },
  },
  {
    name: 'description',
    type: 'richText',
    required: true,
    friendlyName: 'Descrição',
  },
]

const validateListMaxLength = (options: Mapped<ListBlockProps>) => {
  if (options.get('items').length > 12) {
    options.set('items', options.get('items').slice(0, 12))
    alert('Não pode haver mais que 12 itens no List Panel.')
  }
}

Builder.registerComponent(
  dynamic(() => import('.').then((mod) => mod.ListBlock) as never),
  {
    name: BLOCK_NAMES.list.panel,
    inputs: [
      ...builderInputsBlock(),
      {
        ...listLayoutBuilderInput,
        defaultValue: LIST_LAYOUT.panel,
      },
      {
        name: 'items',
        type: 'list',
        onChange: validateListMaxLength,
        subFields: [...panelItemInputs, { ...midiaBuilderInput }],
      },
    ],
  },
)
