export const accordionBuilderInputs = [
  {
    name: 'items',
    friendlyName: 'Items',
    type: 'list',
    subFields: [
      {
        name: 'title',
        friendlyName: 'Título',
        type: 'string',
      },
      {
        name: 'content',
        friendlyName: 'Conteúdo',
        type: 'richText',
      },
    ],
  },
]
