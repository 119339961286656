import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'

import { BLOCK_NAMES } from '@/legacy/blocks/blockNames'
import { hasButtonBuilderInputs } from '@/legacy/blocks/constants'
import { builderInputsBlock } from '@/legacy/blocks/utils'
import { buttonModifierBuilderInput } from '@/legacy/components/Button/constants.register'
import {
  imageBuilderInput,
  midiaTypeBuilderInput,
} from '@/legacy/components/Media/constants.register'

const mediaImageInput = {
  name: 'midia',
  type: 'object',
  friendlyName: 'Mídia',
  subFields: [
    {
      ...midiaTypeBuilderInput,
      defaultValue: 'image',
      hideFromUI: true,
    },
    {
      ...imageBuilderInput,
      // @TODO Bug Showif in Subfield Builder
      showIf: undefined,
    },
  ],
}

const listItemInputs = [
  { ...mediaImageInput },
  {
    name: 'description',
    type: 'richText',
    friendlyName: 'Texto',
  },
]

Builder.registerComponent(
  dynamic<never>(() => import('.').then((mod) => mod.MediaListBlock)),
  {
    name: BLOCK_NAMES.media.list,
    inputs: [
      ...builderInputsBlock(),
      ...hasButtonBuilderInputs,
      {
        ...buttonModifierBuilderInput,
        name: 'buttonModifier',
        showIf: (options) => options.get('hasButton'),
      },
      { ...mediaImageInput },
      {
        name: 'items',
        type: 'list',
        subFields: [...listItemInputs],
      },
    ],
  },
)
