import { Builder } from '@builder.io/react'
import type { Input } from '@builder.io/sdk'
import dynamic from 'next/dynamic'

import { BLOCK_NAMES } from '@/legacy/blocks/blockNames'
import {
  containerBackgroundColorBuilderInput,
  containerBackgroundColorHexBuilderInput,
  containerSpaceOutBuilderInput,
} from '@/legacy/components/Container'

import { hasCountdownBuilderInputs } from '../Countdown/constants'

import { heroWithCtaInputs } from './constants.register'

const heroWithCtaBlockInputs: Input[] = [
  ...heroWithCtaInputs,
  {
    ...containerBackgroundColorBuilderInput,
    name: 'background',
  },
  {
    ...containerBackgroundColorHexBuilderInput,
    name: 'backgroundColor',
    showIf: (options) => options.get('background') === 'custom',
  },

  containerSpaceOutBuilderInput,
  ...hasCountdownBuilderInputs,
]

Builder.registerComponent(
  dynamic<never>(() => import('.').then((mod) => mod.HeroWithCtaBlock)),
  {
    name: BLOCK_NAMES.hero.withCta,
    inputs: heroWithCtaBlockInputs,
  },
)
