import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'

Builder.registerComponent(
  dynamic<never>(() => import('@builder.io/react').then((mod) => mod.RawText), {
    ssr: false,
  }),
  {
    name: 'Text',
    override: true,
    inputs: [
      {
        name: 'text',
        required: true,
        type: 'richText',
      },
    ],
  },
)
