import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'

import { BLOCK_NAMES } from '@/legacy/blocks/blockNames'
import {
  builderInputsBlockWithBackgroundHex,
  verticalPositionBuilderInputs,
} from '@/legacy/blocks/utils'

Builder.registerComponent(
  dynamic<never>(() => import('.').then((mod) => mod.SimpleAnnouncementBarBlock)),
  {
    name: BLOCK_NAMES.simpleAnnouncementBar,
    inputs: [
      ...builderInputsBlockWithBackgroundHex({
        withPosition: false,
        withFontSize: false,
        withTitle: false,
        withSubtitle: false,
        withContainerSpaceOut: false,
        hasAdvancedOptions: true,
      }),
      ...verticalPositionBuilderInputs(true),
      {
        name: 'message',
        friendlyName: 'Mensagem',
        type: 'richText',
        required: true,
      },
    ],
  },
)
