import { Builder } from '@builder.io/react'

import dynamic from 'next/dynamic'

import { BLOCK_NAMES } from '@/legacy/blocks/blockNames'
import { builderInputsBlockWithBackgroundHex } from '@/legacy/blocks/utils'
import {
  imageWithAriaFlagBuilderInput,
  imageBuilderInput,
} from '@/legacy/components/Media/constants.register'

const downloadReferencesInputs = [
  {
    ...imageBuilderInput,
    friendlyName: 'Imagem (11:4)',
  },
  {
    name: 'url',
    type: 'string',
    friendlyName: 'Link de Download',
  },
]

const stepInputs = [
  {
    name: 'number',
    type: 'number',
    friendlyName: 'Numeração',
    required: true,
  },
  {
    name: 'text',
    type: 'richText',
    friendlyName: 'Texto',
    required: true,
  },
  {
    name: 'downloadReferences',
    type: 'list',
    friendlyName: 'Referências a Download',
    subFields: [...downloadReferencesInputs],
  },
]

Builder.registerComponent(
  dynamic<never>(() => import('.').then((mod) => mod.MediaProductStepByStepBlock)),
  {
    name: BLOCK_NAMES.media.productStepByStep,
    inputs: [
      ...builderInputsBlockWithBackgroundHex({
        withPosition: false,
        withFontSize: false,
        withSubtitle: false,
      }),
      { ...imageWithAriaFlagBuilderInput, required: true },
      {
        name: 'steps',
        type: 'list',
        friendlyName: 'Passos',
        subFields: [...stepInputs],
      },
    ],
  },
)
