import type { Input } from '@builder.io/sdk'

import {
  containerBackgroundColorBuilderInput,
  containerBackgroundColorHexBuilderInput,
} from '@/legacy/components/Container'

import type { HasCountdownProps } from '@/legacy/components/Countdown/types'

import { colorModifierBuilderInput } from '../constants'

import type { CountdownBlockProps } from './Countdown'

const typographyColorsWithPrimaryEnum = [
  ...(colorModifierBuilderInput.enum as []),
  { value: 'primary', label: 'Primário' },
]

export const countdownBuilderInputs: Input[] = [
  {
    ...containerBackgroundColorBuilderInput,
    name: 'clockBackGround',
    defaultValue: 'primary',
    friendlyName: 'Cor de fundo do contador',
  },
  {
    ...containerBackgroundColorHexBuilderInput,
    name: 'clockBackGroundHex',
    friendlyName: 'Cor de fundo customizada do contador',
    showIf: (options) => options.get('clockBackGround') === 'custom',
  },
  {
    ...colorModifierBuilderInput,
    name: 'labelColorModifier',
    friendlyName: 'Cor do label do contador',
    enum: typographyColorsWithPrimaryEnum,
  },
  {
    ...colorModifierBuilderInput,
    name: 'clockColorModifier',
    defaultValue: 'light',
    friendlyName: 'Cor do contador',
    enum: typographyColorsWithPrimaryEnum,
  },
  {
    name: 'countdownDate',
    friendlyName: 'Data',
    type: 'datetime-local',
  },
  {
    name: 'countdownDay',
    friendlyName: 'Mostrar em horas abaixo de x dias',
    type: 'number',
    defaultValue: 2,
    helperText:
      'Ex: Mostrar em horas abaixo de 2 dias, antes de 2 dias ele mostrará em horas, minutos e segundos ',
  },
]

export const hasCountdownBuilderInput: Input = {
  name: 'hasCountdown',
  type: 'boolean',
  friendlyName: 'Exibir countdown',
  defaultValue: false,
  required: false,
}

const countdownInputsWithShowIf = countdownBuilderInputs.map((input) => ({
  ...input,
  showIf: (options: Mapped<CountdownBlockProps & HasCountdownProps>) => options.get('hasCountdown'),
}))

export const hasCountdownBuilderInputs = [hasCountdownBuilderInput, ...countdownInputsWithShowIf]
