import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'

import { BLOCK_NAMES } from '@/legacy/blocks/blockNames'
import { builderInputsBlockWithBackgroundHex } from '@/legacy/blocks/utils'
import { buttonModifierBuilderInput } from '@/legacy/components/Button/constants.register'

Builder.registerComponent(
  dynamic<never>(() => import('.').then((mod) => mod.CtaBlock)),
  {
    name: BLOCK_NAMES.cta,
    inputs: [
      ...builderInputsBlockWithBackgroundHex({ withFontSize: false }),
      {
        name: 'withBorder',
        friendlyName: 'Com bordas',
        type: 'boolean',
        defaultValue: true,
      },
      {
        name: 'hasLargeTitle',
        type: 'boolean',
        friendlyName: 'Título Grande',
        defaultValue: false,
      },
      {
        name: 'button',
        type: 'object',
        friendlyName: 'Botão Link',
        defaultValue: {
          label: 'Texto botão link...',
          link: 'https://www.serasa.com.br/entrar',
          rounded: false,
        },
        subFields: [
          {
            name: 'label',
            type: 'string',
            friendlyName: 'Texto',
            required: true,
          },
          {
            name: 'link',
            friendlyName: 'Link',
            type: 'string',
            required: true,
          },
          {
            name: 'rounded',
            type: 'boolean',
            friendlyName: 'Arredondado',
            defaultValue: false,
          },
          { ...buttonModifierBuilderInput },
        ],
      },
    ],
  },
)
