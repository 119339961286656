import { Builder } from '@builder.io/react'

import dynamic from 'next/dynamic'

import { BLOCK_NAMES } from '@/legacy/blocks/blockNames'
import { containerSpaceInBuilderInput } from '@/legacy/components/Container'
import {
  imageBuilderInput,
  midiaTypeBuilderInput,
} from '@/legacy/components/Media/constants.register'

import { settings, settingsText } from './constants.register'

Builder.registerComponent(
  dynamic<never>(() => import('.').then((mod) => mod.PartnerSearchListBlock)),
  {
    name: BLOCK_NAMES.list.searchPartner,
    inputs: [
      {
        name: 'items',
        friendlyName: 'Parceiros',
        type: 'list',
        subFields: [
          {
            name: 'name',
            type: 'string',
          },
          {
            name: 'link',
            type: 'string',
            friendlyName: 'Link URL',
          },
          {
            name: 'midia',
            type: 'object',
            friendlyName: 'Mídia',
            subFields: [
              {
                ...midiaTypeBuilderInput,
                defaultValue: 'image',
                hideFromUI: true,
              },
              {
                ...imageBuilderInput,
              },
            ],
          },
        ],
      },
      {
        ...containerSpaceInBuilderInput,
        advanced: true,
      },
      ...settingsText,
      settings,
    ],
  },
)
