import { Builder } from '@builder.io/sdk'
import dynamic from 'next/dynamic'

import { BLOCK_NAMES } from '@/legacy/blocks/blockNames'

Builder.registerComponent(
  dynamic<never>(() => import('.').then((mod) => mod.ChatbotWhatsappBlock)),
  {
    name: BLOCK_NAMES.chatbotWhatsapp,
  },
)
