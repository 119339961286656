import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'

import { BLOCK_NAMES } from '@/legacy/blocks/blockNames'

import { containerSpaceOutBuilderInput } from './../../components/Container/constants'

Builder.registerComponent(
  dynamic<never>(() => import('.').then((mod) => mod.BigNumbersBlock)),
  {
    name: BLOCK_NAMES.bigNumbers,
    inputs: [
      {
        name: 'items',
        friendlyName: 'Big number',
        type: 'list',
        subFields: [
          {
            name: 'highlightedNumber',
            friendlyName: 'Numero em destaque',
            helperText: 'ex: 243 milhões',
            type: 'string',
          },
          {
            name: 'textAfterNumber',
            friendlyName: 'Texto a seguir do numero',
            helperText: 'ex: de pedidos',
            type: 'string',
          },
        ],
      },
      {
        ...containerSpaceOutBuilderInput,
        name: 'space',
      },
    ],
  },
)
