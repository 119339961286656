import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'

import { BLOCK_NAMES } from '@/legacy/blocks/blockNames'
import {
  builderInputsBlockWithBackgroundHex,
  verticalPositionBuilderInputs,
} from '@/legacy/blocks/utils'

Builder.registerComponent(
  dynamic<never>(() => import('.').then((mod) => mod.PersistentAnnouncementBarBlock)),
  {
    name: BLOCK_NAMES.persistentAnnouncementBar,
    requiredPermissions: ['admin'],
    inputs: [
      {
        name: 'persistentState',
        friendlyName: 'Gerenciar estado persistente',
        helperText: 'Habilite e configure os cookies para persistência de estado.',
        type: 'object',
        advanced: true,
        defaultValue: { allowPersistentState: false, cookies: [] },
        subFields: [
          {
            name: 'allowPersistentState',
            friendlyName: 'Estado persistente',
            helperText: 'Habilite caso deseja persistir o estado ao fechar o anúncio',
            type: 'boolean',
          },
          {
            name: 'cookies',
            friendlyName: 'Cookies de persistência',
            type: 'list',
            subFields: [
              {
                name: 'name',
                friendlyName: 'Nome do cookie',
                type: 'string',
              },
              {
                name: 'value',
                friendlyName: 'Valor do cookie',
                type: 'string',
              },
              { name: 'isDefault', friendlyName: 'É padrão', type: 'boolean', defaultValue: false },
            ],
          },
        ],
      },
      ...builderInputsBlockWithBackgroundHex({
        withPosition: false,
        withFontSize: false,
        withTitle: false,
        withSubtitle: false,
        withContainerSpaceOut: false,
        hasAdvancedOptions: true,
      }),
      ...verticalPositionBuilderInputs(true),
      {
        name: 'message',
        friendlyName: 'Mensagem',
        type: 'richText',
        required: true,
      },
    ],
  },
)
