import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'

import { BLOCK_NAMES } from '@/legacy/blocks/blockNames'

Builder.registerComponent(
  dynamic<never>(() => import('.').then((mod) => mod.Custom)),
  {
    name: BLOCK_NAMES.custom.default,
    inputs: [
      {
        name: 'type',
        type: 'string',
        friendlyName: 'Componente customizado',
        enum: [
          { value: 'AGENCIES', label: 'Agencias' },
          { value: 'ECRED_LOAN_SIMULATOR', label: 'eCred Simulador de Empréstimo' },
        ],
      },
      {
        name: 'disclaimer',
        type: 'richText',
        friendlyName: 'Disclaimer',
      },
      {
        name: 'agencies',
        type: 'list',
        friendlyName: 'Locais/Agências',
        subFields: [
          {
            name: 'id',
            type: 'string',
            friendlyName: 'UF',
          },
          {
            name: 'state',
            type: 'string',
            friendlyName: 'Estado',
          },
          {
            name: 'locals',
            type: 'list',
            friendlyName: 'Agências',
            subFields: [
              {
                name: 'id',
                type: 'string',
                hideFromUI: true,
              },
              {
                name: 'title',
                type: 'string',
                friendlyName: 'Título',
              },
              {
                name: 'adreess',
                type: 'string',
                friendlyName: 'Endereço',
              },
              {
                name: 'openingHours',
                type: 'string',
                friendlyName: 'Horário de atendimento',
                helperText:
                  'De segunda a sexta, das 9h às 16h. Apenas consulta de extratos negativos. Para envio de documentos, enviar para: ...',
              },
              {
                name: 'link',
                type: 'string',
                friendlyName: 'URL Google Maps',
              },
            ],
          },
        ],
      },
    ],
  },
)
