import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'

import { BLOCK_NAMES } from '@/legacy/blocks/blockNames'
import { titleBuilderInput } from '@/legacy/blocks/constants'
import { builderInputsBlock } from '@/legacy/blocks/utils'
import { midiaBuilderInput } from '@/legacy/components/Media/constants.register'

import { listLayoutBuilderInput, LIST_LAYOUT } from './constants'

const mediaItemInputs = [
  {
    ...titleBuilderInput,
    defaultValue: {
      text: 'Título',
      tag: 'h4',
    },
  },
  {
    name: 'description',
    type: 'richText',
    friendlyName: 'Descrição',
  },
]

Builder.registerComponent(
  dynamic(() => import('.').then((mod) => mod.ListBlock) as never),
  {
    name: BLOCK_NAMES.list.medias,
    inputs: [
      ...builderInputsBlock(),
      {
        ...listLayoutBuilderInput,
        defaultValue: LIST_LAYOUT.medias,
      },
      {
        name: 'items',
        type: 'list',
        subFields: [...mediaItemInputs, { ...midiaBuilderInput }],
      },
    ],
  },
)
