import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'

import { BLOCK_NAMES } from '@/legacy/blocks/blockNames'
import { idBuilderInput } from '@/legacy/blocks/constants'
import {
  containerBackgroundColorBuilderInput,
  containerBackgroundColorHexBuilderInput,
  containerSpaceInBuilderInput,
  containerSpaceOutBuilderInput,
} from '@/legacy/components/Container'

Builder.registerComponent(
  dynamic<never>(() => import('.').then((mod) => mod.AdsBlock)),
  {
    name: BLOCK_NAMES.ads,
    inputs: [
      {
        name: 'type',
        type: 'string',
        friendlyName: 'Tipo de banner',
        defaultValue: 'leaderboard',
        required: true,
        enum: [
          {
            label: 'Leaderboard topo',
            value: 'leaderboard_top',
          },
          {
            label: 'Leaderboard',
            value: 'leaderboard',
          },
          {
            label: 'Artigo topo',
            value: 'intercontent_top',
          },
          {
            label: 'Artigo meio',
            value: 'intercontent_middle',
          },
          {
            label: 'Artigo rodapé',
            value: 'intercontent_bottom',
          },
          {
            label: 'Arroba',
            value: 'arroba',
          },
        ],
      },
      {
        ...containerSpaceOutBuilderInput,
        required: false,
        name: 'space',
        advanced: true,
      },
      { ...containerBackgroundColorBuilderInput, advanced: true, name: 'background' },
      { ...containerBackgroundColorHexBuilderInput, advanced: true },
      { ...containerSpaceInBuilderInput, advanced: true },
      {
        name: 'adUnitSlotId',
        type: 'string',
        friendlyName: 'Banner ID',
        defaultValue: '',
        required: true,
      },
      idBuilderInput,
    ],
  },
)
