import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'

import { NOTIFICATION_DEFAULT_PROPS, SHARING_LABEL } from '@/legacy/components/SharingButtons/utils'

import { BLOCK_NAMES } from '../blockNames'
import { builderInputsBlock, verticalPositionBuilderInputs } from '../utils'

Builder.registerComponent(
  dynamic<never>(() => import('.').then((mod) => mod.SharingButtons)),
  {
    name: BLOCK_NAMES.sharingButtons,
    inputs: [
      ...builderInputsBlock({
        hasAdvancedOptions: true,
        withContainerSpaceOut: false,
        withContainerSpaceIn: true,
        withBackground: false,
        withFontSize: false,
        withSubtitle: false,
        withTitle: false,
      }),
      {
        name: 'notification',
        type: 'object',
        friendlyName: 'Opções de notificação',
        subFields: [...verticalPositionBuilderInputs()],
        advanced: true,
        defaultValue: NOTIFICATION_DEFAULT_PROPS,
      },
      {
        name: 'variations',
        type: 'string',
        friendlyName: 'Variações de estilo',
        defaultValue: 'ghost-icons',
        enum: [
          {
            label: 'Ícones ghost',
            value: 'ghost-icons',
          },
          {
            label: 'Ícones cinza',
            value: 'gray-icons',
          },
        ],
      },
      {
        name: 'sharingLabel',
        type: 'string',
        friendlyName: 'Rotulo de compartilhamento',
        defaultValue: SHARING_LABEL,
        showIf: (options) => options.get('variations') === 'gray-icons',
      },
    ],
  },
)
