import type { Input } from '@builder.io/sdk'

export const buttonValueLinkBuilderInput: Input = {
  name: 'value',
  type: 'string',
  friendlyName: 'Link URL',
  required: true,
  showIf: (options) => options.get('action') === 'link',
}

export const buttonValueScrollBuilderInput: Input = {
  name: 'value',
  type: 'string',
  friendlyName: 'Ação de Scroll (ID elemento)',
  required: true,
  showIf: (options) => options.get('action') === 'scroll',
}

export const buttonValueBuilderInputs: Input[] = [
  buttonValueLinkBuilderInput,
  buttonValueScrollBuilderInput,
]

export const buttonModifierBuilderInput: Input = {
  name: 'modifier',
  type: 'string',
  friendlyName: 'Botão - Estilo',
  defaultValue: 'solid',
  enum: [
    { label: 'Primário', value: 'solid' },
    { label: 'Contornado', value: 'outline' },
    { label: 'Fantasma', value: 'ghost' },
    { label: 'Primário invertido', value: 'solid-invert' },
    { label: 'Link', value: 'link' },
  ],
}

export const generateButtonActionInputs = ({
  list = ['sem ação', 'link', 'scroll', 'modal'],
  defaultValue = 'sem ação',
}: {
  list?: ('link' | 'scroll' | 'sem ação' | 'modal')[]
  defaultValue?: 'link' | 'scroll' | 'sem ação' | 'modal'
} = {}): Input[] => [
  {
    name: 'action',
    type: 'string',
    friendlyName: 'Ação customizada',
    defaultValue,
    enum: list,
    hideFromUI: list && defaultValue && list.length === 1 && list.includes(defaultValue),
  },
  ...buttonValueBuilderInputs,
]
