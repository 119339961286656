import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'

import { builderInputsBlock } from '@/legacy/blocks/utils'

import { BLOCK_NAMES } from '../blockNames'

const inputsBlock = builderInputsBlock({
  withBackground: false,
  withTitle: false,
  withSubtitle: false,
  withContainerSpaceIn: true,
  withFontSize: false,
}).map((input) => ({ ...input, advanced: true }))

Builder.registerComponent(
  dynamic<never>(() => import('.').then((mod) => mod.TableBlock)),
  {
    name: BLOCK_NAMES.table,
    inputs: [
      {
        name: 'headings',
        friendlyName: 'Cabeçalhos',
        type: 'list',
        defaultValue: [
          { text: 'Cabeçalho 1' },
          { text: 'Cabeçalho 2' },
          { text: 'Cabeçalho 3' },
          { text: 'Cabeçalho 4' },
        ],
        subFields: [
          {
            name: 'text',
            friendlyName: 'Texto',
            type: 'text',
          },
        ],
      },
      {
        name: 'rows',
        friendlyName: 'Linhas',
        type: 'list',
        defaultValue: [
          {
            cells: [
              { text: 'Célula 1' },
              { text: 'Célula 2' },
              { text: 'Célula 3' },
              { text: 'Célula 4' },
            ],
          },
        ],
        subFields: [
          {
            name: 'cells',
            friendlyName: 'Células',
            type: 'list',
            defaultValue: [],
            subFields: [{ name: 'text', friendlyName: 'Texto', type: 'text' }],
          },
        ],
      },
      {
        name: 'roundedCorners',
        friendlyName: 'Bordas Arrendondadas',
        type: 'boolean',
        defaultValue: true,
        advanced: true,
      },
      {
        name: 'fontWeight',
        friendlyName: 'Espessura da Fonte',
        type: 'text',
        defaultValue: '400',
        enum: [
          { label: 'Regular', value: '400' },
          { label: 'Bold', value: '700' },
        ],
        advanced: true,
      },
      ...inputsBlock,
    ],
  },
)
