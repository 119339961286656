import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'

import { BLOCK_NAMES } from '@/legacy/blocks/blockNames'
import { builderInputsBlock } from '@/legacy/blocks/utils'

import { LIST_LAYOUT, listLayoutBuilderInput } from './constants'

Builder.registerComponent(
  dynamic(() => import('.').then((mod) => mod.ListBlock) as never),
  {
    name: BLOCK_NAMES.list.default,
    inputs: [
      ...builderInputsBlock(),
      {
        name: 'type',
        type: 'string',
        friendlyName: 'Tipo de lista',
        defaultValue: 'unordered',
        enum: [
          { value: 'ordered', label: 'Ordenado' },
          { value: 'unordered', label: 'Não ordenado' },
        ],
      },
      {
        ...listLayoutBuilderInput,
        defaultValue: LIST_LAYOUT.list,
      },
      {
        name: 'items',
        type: 'list',
        subFields: [
          {
            name: 'content',
            friendlyName: 'Item',
            defaultValue: '<p>Descrição do item...</p>',
            type: 'richText',
          },
        ],
      },
    ],
  },
)
