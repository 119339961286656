import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'

import { BLOCK_NAMES } from '@/legacy/blocks/blockNames'

Builder.registerComponent(
  dynamic<never>(() => import('.').then((mod) => mod.MediaWithFormsBlock)),
  {
    name: BLOCK_NAMES.premium.mediaWithForms,
    inputs: [
      { name: 'title', type: 'text', defaultValue: 'Consulta de CPF e CNPJ de terceiros' },
      {
        name: 'subtitle',
        type: 'richText',
        defaultValue:
          'Aqui você pode consultar a situação cadastral de terceiros por apenas R$35,00 a consulta. O resultado sai na hora!',
      },
      {
        name: 'image',
        type: 'object',
        subFields: [
          {
            name: 'url',
            type: 'file',
            allowedFileTypes: ['jpg', 'jpeg', 'png', 'svg', 'webp'],
          },
          { name: 'alt', type: 'text' },
        ],
      },
      {
        name: 'position',
        type: 'text',
        defaultValue: 'left',
        enum: [
          { label: 'Esquerda', value: 'left' },
          { label: 'Direita', value: 'right' },
        ],
      },
      {
        name: 'disclaimer',
        type: 'object',
        defaultValue: {
          text: 'Consulte seu próprio CPF gratuitamente',
        },
        subFields: [
          {
            name: 'text',
            type: 'richText',
            defaultValue: 'Consulte seu próprio CPF gratuitamente',
          },
          { name: 'accessibleText', type: 'text' },
        ],
      },
    ],
  },
)
