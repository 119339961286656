import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'

import { containerSpaceOutBuilderInput } from '@/legacy/components/Container'
import { imageBuilderInput } from '@/legacy/components/Media/constants.register'

import { BLOCK_NAMES } from '../blockNames'
import { idBuilderInput, titleTagBuilderInputSubFields } from '../constants'

const TEXT_SUBFIELDS = [
  { name: 'text', type: 'string', friendlyName: 'Texto' },
  { ...titleTagBuilderInputSubFields },
]

Builder.registerComponent(
  dynamic<never>(() => import('.').then((mod) => mod.ProductSolutionCarousel)),
  {
    name: BLOCK_NAMES.carousel.productSolution,
    requiredPermissions: ['admin'],
    inputs: [
      {
        name: 'heading',
        type: 'object',
        friendlyName: 'Cabeçalho',
        defaultValue: {
          complementaryTitle: {
            text: 'Título complementar',
            tag: 'h3',
          },
          title: {
            text: 'Título',
            tag: 'h2',
          },
        },
        subFields: [
          {
            name: 'complementaryTitle',
            type: 'object',
            friendlyName: 'Título Complementar',
            subFields: TEXT_SUBFIELDS,
          },
          {
            name: 'title',
            type: 'object',
            friendlyName: 'Título',
            subFields: TEXT_SUBFIELDS,
          },
        ],
      },
      {
        name: 'items',
        type: 'list',
        required: true,
        subFields: [
          {
            name: 'legend',
            type: 'object',
            friendlyName: 'Legenda',
            defaultValue: {
              label: 'Label do item',
            },
            subFields: [
              {
                ...imageBuilderInput,
                name: 'icon',
                friendlyName: 'Ícone do botão',
              },
              {
                name: 'label',
                type: 'string',
                friendlyName: 'Rótulo do botão',
              },
            ],
          },
          {
            name: 'content',
            type: 'object',
            friendlyName: 'Corpo do slide',
            defaultValue: {
              description: {
                text: 'Descrição do slide',
                tag: 'h3',
              },
              cta: {
                label: 'Rótulo do CTA',
                url: 'https://www.serasa.com.br/',
              },
            },
            subFields: [
              {
                name: 'description',
                type: 'object',
                friendlyName: 'Descrição do slide',
                subFields: [
                  { name: 'text', type: 'richText', friendlyName: 'Texto' },
                  { ...titleTagBuilderInputSubFields },
                ],
              },
              { ...imageBuilderInput },
              {
                name: 'cta',
                type: 'object',
                friendlyName: 'CTA',
                subFields: [
                  {
                    name: 'label',
                    type: 'string',
                    friendlyName: 'Rótulo',
                    required: true,
                  },
                  {
                    name: 'accessibleLabel',
                    type: 'string',
                    friendlyName: 'Rótulo para acessibilidade',
                  },
                  {
                    name: 'url',
                    type: 'string',
                    friendlyName: 'URL',
                    required: true,
                  },
                ],
              },
            ],
          },
        ],
      },
      { ...idBuilderInput, advanced: true },
      {
        name: 'backgroundColor',
        friendlyName: 'Cor de fundo',
        type: 'string',
        enum: [
          { value: 'gray', label: 'Cinza' },
          { value: 'snow', label: 'Branco' },
        ],
        advanced: true,
      },
      { ...containerSpaceOutBuilderInput, advanced: true },
    ],
  },
)
