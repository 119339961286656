import { Builder } from '@builder.io/react'

import dynamic from 'next/dynamic'

import { BLOCK_NAMES } from '@/legacy/blocks/blockNames'
import { subtitleBuilderInput, titleBuilderInput } from '@/legacy/blocks/constants'
import { builderInputsBlock } from '@/legacy/blocks/utils'
import { imageWithAriaFlagBuilderInput } from '@/legacy/components/Media/constants.register'

const mediaCardInputs = [
  {
    ...imageWithAriaFlagBuilderInput,
  },
  {
    ...titleBuilderInput,
  },
  {
    ...subtitleBuilderInput,
    name: 'description',
  },
]

Builder.registerComponent(
  dynamic<never>(() => import('.').then((mod) => mod.MediaCardListBlock)),
  {
    name: BLOCK_NAMES.media.cardList,
    inputs: [
      ...builderInputsBlock({
        withBackground: false,
        withFontSize: false,
        withPosition: false,
      }),
      {
        name: 'cards',
        type: 'list',
        friendlyName: 'Cards',
        subFields: mediaCardInputs,
      },
    ],
  },
)
