import { sendError } from '@/legacy/core/error'

export function copyToClipboard(text: string) {
  try {
    return navigator.clipboard?.writeText(text).catch((error) => {
      sendError({ error })
      return Promise.reject(error)
    })
  } catch (error) {
    sendError({ error })
  }
}
