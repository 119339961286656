import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'

import { BLOCK_NAMES } from '@/legacy/blocks/blockNames'
import { builderInputsBlockWithBackgroundHex } from '@/legacy/blocks/utils'

Builder.registerComponent(
  dynamic<never>(() => import('.').then((mod) => mod.CustomForm)),
  {
    name: BLOCK_NAMES.custom.form,
    inputs: [
      ...builderInputsBlockWithBackgroundHex(),
      {
        name: 'button',
        type: 'object',
        required: true,
        defaultValue: {
          label: 'Confirmar',
        },
        subFields: [
          {
            name: 'label',
            type: 'string',
            friendlyName: 'Botão - Texto',
            required: true,
          },
        ],
      },
      {
        name: 'xhrUrl',
        type: 'string',
        friendlyName: 'URL da API método POST',
        required: true,
      },
      {
        name: 'formSuccessMessage',
        type: 'string',
        friendlyName: 'Mensagem de sucesso',
        required: true,
      },
      {
        name: 'formErrorMessage',
        type: 'string',
        friendlyName: 'Menssagem de erro',
        required: true,
      },
      {
        name: 'observations',
        type: 'richText',
        friendlyName: 'Observações',
        required: true,
      },
      {
        name: 'submitRedirectUrl',
        type: 'string',
        friendlyName: 'URL redirecionamento',
        required: true,
      },
      {
        name: 'fields',
        type: 'list',
        friendlyName: 'Campos de formulário',
        required: true,
        subFields: [
          {
            name: 'label',
            type: 'string',
          },
          {
            name: 'name',
            type: 'string',
          },
          {
            name: 'type',
            type: 'string',
          },
          {
            name: 'placeholder',
            type: 'string',
          },
          {
            name: 'pattern',
            type: 'string',
          },
          {
            name: 'mask',
            type: 'string',
          },
          {
            name: 'errorMessage',
            friendlyName: 'Mensagem de erro',
            type: 'string',
          },
        ],
      },
    ],
  },
)
