import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'

import { BLOCK_NAMES } from '@/legacy/blocks/blockNames'

Builder.registerComponent(
  dynamic<never>(() => import('.').then((mod) => mod.HandOnTheWheelBlock)),
  {
    name: BLOCK_NAMES.bannerMNR,
    inputs: [
      {
        name: 'appStoreIcon',
        friendlyName: 'Ícone da App Store',
        type: 'file',
        allowedFileTypes: ['png', 'svg', 'jpeg', 'jpg'],
        defaultValue: '',
      },
      {
        name: 'downloadAppIcon',
        friendlyName: 'Ícone do Passo "Baixar App"',
        type: 'file',
        allowedFileTypes: ['png', 'svg', 'jpeg', 'jpg'],
        defaultValue: '',
      },
      {
        name: 'gold',
        friendlyName: 'Imagem da Barra de Ouro',
        type: 'file',
        allowedFileTypes: ['png', 'svg', 'jpeg', 'jpg'],
        defaultValue: '',
      },
      {
        name: 'receiveNumbersIcon',
        friendlyName: 'Ícone do Passo "Ganhar números da Sorte"',
        type: 'file',
        allowedFileTypes: ['png', 'svg', 'jpeg', 'jpg'],
        defaultValue: '',
      },
      {
        name: 'googlePlayIcon',
        friendlyName: 'Ícone da Play Store',
        type: 'file',
        allowedFileTypes: ['png', 'svg', 'jpeg', 'jpg'],
        defaultValue: '',
      },
      {
        name: 'kv',
        friendlyName: 'Imagem da Campanha',
        type: 'file',
        allowedFileTypes: ['png', 'svg', 'jpeg', 'jpg'],
        defaultValue: '',
      },
      {
        name: 'kvDesk',
        friendlyName: 'Imagem da Campanha (Desktop)',
        type: 'file',
        allowedFileTypes: ['png', 'svg', 'jpeg', 'jpg'],
        defaultValue: '',
      },
      {
        name: 'money',
        friendlyName: 'Imagem do 100 mil',
        type: 'file',
        allowedFileTypes: ['png', 'svg', 'jpeg', 'jpg'],
        defaultValue: '',
      },
      {
        name: 'useServicesIcon',
        friendlyName: 'Imagem do Passo "Usar serviços Serasa"',
        type: 'file',
        allowedFileTypes: ['png', 'svg', 'jpeg', 'jpg'],
        defaultValue: '',
      },
    ],
  },
)
