import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'

import { BLOCK_NAMES } from '@/legacy/blocks/blockNames'

Builder.registerComponent(
  dynamic<never>(() => import('.').then((mod) => mod.BlackFridayBlock)),
  {
    name: BLOCK_NAMES.blackFriday,
    inputs: [],
  },
)
