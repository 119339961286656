import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'

import { BLOCK_NAMES } from '@/legacy/blocks/blockNames'
import {
  analytics,
  buttonAriaLabelBuilderInput,
  hasButtonBuilderInput,
} from '@/legacy/blocks/constants'
import { builderInputsBlock } from '@/legacy/blocks/utils'
import { buttonModifierBuilderInput } from '@/legacy/components/Button/constants.register'
import {
  imageBuilderInput,
  midiaTypeBuilderInput,
  videoBuilderInput,
} from '@/legacy/components/Media/constants.register'

Builder.registerComponent(
  dynamic<never>(() => import('.').then((mod) => mod.MediaBlock)),
  {
    name: BLOCK_NAMES.media.default,
    inputs: [
      analytics,
      ...builderInputsBlock({ withBackground: false }),
      {
        name: 'content',
        type: 'richText',
        friendlyName: 'Conteúdo',
      },
      hasButtonBuilderInput,
      {
        name: 'button',
        type: 'object',
        friendlyName: 'Botão Link',
        showIf: (options) => options.get('hasButton'),
        defaultValue: {
          label: 'Texto botão link...',
          link: 'https://www.serasa.com.br/entrar',
        },
        subFields: [
          {
            name: 'label',
            type: 'string',
            friendlyName: 'Texto',
            required: true,
          },
          {
            name: 'link',
            type: 'string',
            required: true,
          },
          {
            name: 'rounded',
            type: 'boolean',
            friendlyName: 'Arredondado',
            defaultValue: false,
          },
          {
            ...buttonAriaLabelBuilderInput,
            name: 'ariaLabel',
          },
          {
            ...buttonModifierBuilderInput,
            required: true,
          },
        ],
      },
      { ...midiaTypeBuilderInput },
      { ...imageBuilderInput },
      { ...videoBuilderInput },
    ],
  },
)
