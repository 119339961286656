import { Builder } from '@builder.io/react'

import dynamic from 'next/dynamic'

import { BLOCK_NAMES } from '@/legacy/blocks/blockNames'
import { builderInputsBlock } from '@/legacy/blocks/utils'
import { imageWithAriaFlagBuilderInput } from '@/legacy/components/Media/constants.register'

const mediaProductGuideButtonInputs = [
  {
    name: 'text',
    type: 'string',
    friendlyName: 'Texto',
    defaultValue: ' ',
  },
  {
    name: 'url',
    type: 'string',
    friendlyName: 'URL',
    defaultValue: ' ',
  },
]

const mediaProductGuidesInputs = [
  {
    ...imageWithAriaFlagBuilderInput,
  },
  {
    name: 'shortDescription',
    type: 'richText',
    friendlyName: 'Descrição curta',
  },
  {
    name: 'button',
    type: 'object',
    friendlyName: 'Botão',
    subFields: mediaProductGuideButtonInputs,
  },
]

Builder.registerComponent(
  dynamic<never>(() => import('.').then((mod) => mod.MediaProductGuidesBlock)),
  {
    name: BLOCK_NAMES.media.productGuides,
    inputs: [
      ...builderInputsBlock({
        withBackground: false,
        withFontSize: false,
        withPosition: false,
      }),
      {
        name: 'guides',
        type: 'list',
        friendlyName: 'Guias',
        subFields: mediaProductGuidesInputs,
      },
    ],
  },
)
