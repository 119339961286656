import { Builder } from '@builder.io/react'
import type { Input } from '@builder.io/sdk'
import dynamic from 'next/dynamic'

import { BLOCK_NAMES } from '@/legacy/blocks/blockNames'
import { builderInputsBlock } from '@/legacy/blocks/utils'

import { buttonModifierBuilderInput } from '@/legacy/components/Button/constants.register'

const ctaInputs: Input = {
  name: 'cta',
  type: 'object',
  friendlyName: 'CTA',
  defaultValue: {
    label: 'Buscar ofertas de empréstimo',
    rounded: false,
    modifier: 'solid',
  },
  showIf: (options) => options.get('hasCta'),
  advanced: true,
  subFields: [
    buttonModifierBuilderInput,
    {
      name: 'label',
      type: 'string',
      friendlyName: 'Texto customizado',
      required: true,
    },
    {
      name: 'rounded',
      type: 'boolean',
      friendlyName: 'Arredondado',
    },
  ],
}

Builder.registerComponent(
  dynamic<never>(() => import('.').then((mod) => mod.LoanSimulatorBlock)),
  {
    name: BLOCK_NAMES.loan.default,
    inputs: [
      ...builderInputsBlock({ withPosition: false, hasAdvancedOptions: true }),
      ctaInputs,
      {
        name: 'hasCta',
        type: 'boolean',
        friendlyName: 'Exibir CTA',
        defaultValue: true,
        required: false,
      },
    ],
  },
)
