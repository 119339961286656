import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'

import { BLOCK_NAMES } from '@/legacy/blocks/blockNames'
import { builderInputsBlockWithBackgroundHex } from '@/legacy/blocks/utils'

Builder.registerComponent(
  dynamic<never>(() => import('.').then((mod) => mod.DisclaimerPremiumBlock)),
  {
    name: BLOCK_NAMES.premium.disclaimerPremium,
    inputs: [...builderInputsBlockWithBackgroundHex({ withFontSize: false })],
  },
)
