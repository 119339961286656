import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'

import { BLOCK_NAMES } from '@/legacy/blocks/blockNames'

import { titleBuilderInput } from '../../constants'

Builder.registerComponent(
  dynamic<never>(() => import('.').then((mod) => mod.StepsBlock)),
  {
    name: BLOCK_NAMES.premium.steps,
    inputs: [
      {
        ...titleBuilderInput,
      },
      {
        name: 'items',
        friendlyName: 'Step',
        type: 'list',
        subFields: [
          {
            name: 'highlightedNumber',
            friendlyName: 'Número referente ao passo',
            helperText: 'ex: 1',
            type: 'string',
          },
          {
            name: 'titleAfterNumber',
            friendlyName: 'Título referente ao passo',
            helperText: 'ex: Faça tal coisa',
            type: 'string',
          },
          {
            name: 'subtitleAfterNumber',
            friendlyName: 'Subtítulo referente ao passo',
            helperText: 'ex: Faça tal coisa',
            type: 'string',
          },
        ],
      },
    ],
  },
)
