import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'

import { BLOCK_NAMES } from '@/legacy/blocks/blockNames'
import { subtitleBuilderInput, titleBuilderInput } from '@/legacy/blocks/constants'
import { builderInputsBlock } from '@/legacy/blocks/utils'
import { midiaBuilderInput } from '@/legacy/components/Media/constants.register'

Builder.registerComponent(
  dynamic<never>(() => import('.').then((mod) => mod.MediaTestimonialBlock)),
  {
    name: BLOCK_NAMES.media.testimonial,
    inputs: [
      ...builderInputsBlock({ withBackground: false, withPosition: false }),
      {
        name: 'content',
        type: 'object',
        friendlyName: 'Citação',
        subFields: [titleBuilderInput, subtitleBuilderInput],
      },
      { ...midiaBuilderInput },
    ],
  },
)
