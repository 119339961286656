import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'

import { BLOCK_NAMES } from '@/legacy/blocks/blockNames'
import { imageBuilderInput } from '@/legacy/components/Media/constants.register'

import { builderInputsBlock } from '../utils'

Builder.registerComponent(
  dynamic<never>(() => import('.').then((mod) => mod.ProductCarousel)),
  {
    name: BLOCK_NAMES.carousel.product,
    inputs: [
      ...builderInputsBlock({ withBackground: false, withFontSize: false, withPosition: false }),
      {
        name: 'cards',
        type: 'list',
        subFields: [{ ...imageBuilderInput }],
      },
    ],
  },
)
