import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'

import { BLOCK_NAMES } from '@/legacy/blocks/blockNames'
import {
  analytics,
  buttonAriaLabelBuilderInput,
  hasButtonBuilderInputs,
} from '@/legacy/blocks/constants'
import { builderInputsBlockWithBackgroundHex } from '@/legacy/blocks/utils'
import { midiaBuilderInput } from '@/legacy/components/Media/constants.register'

import { hasCountdownBuilderInputs } from '../Countdown/constants'

Builder.registerComponent(
  dynamic<never>(() => import('.').then((mod) => mod.HeroProductBlock)),
  {
    name: BLOCK_NAMES.hero.product,
    inputs: [
      analytics,
      ...builderInputsBlockWithBackgroundHex({ withPosition: false, isHero: true }),
      {
        name: 'isSubtitleBold',
        type: 'boolean',
        defaultValue: true,
        friendlyName: 'Subtítulo em negrito',
      },
      midiaBuilderInput,
      ...hasButtonBuilderInputs,
      buttonAriaLabelBuilderInput,
      ...hasCountdownBuilderInputs,
    ],
  },
)
