import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'

import { BLOCK_NAMES } from '@/legacy/blocks/blockNames'
import { builderInputsBlockWithBackgroundHex } from '@/legacy/blocks/utils'
import { imageWithAriaFlagBuilderInput } from '@/legacy/components/Media/constants.register'

const segregatedContentInput = {
  name: 'segregatedContent',
  type: 'object',
  friendlyName: 'Conteúdo em Partes',
  subFields: [
    {
      name: 'firstPart',
      type: 'richText',
      friendlyName: 'Parte 1',
      required: true,
    },
    {
      name: 'secondPart',
      type: 'richText',
      friendlyName: 'Parte 2',
      required: true,
    },
  ],
}

Builder.registerComponent(
  dynamic<never>(() => import('.').then((mod) => mod.MediaWithBackgroundBlock)),
  {
    name: BLOCK_NAMES.media.withBackground,
    inputs: [
      ...builderInputsBlockWithBackgroundHex({
        withFontSize: false,
        withPosition: false,
        withSubtitle: false,
      }),
      { ...segregatedContentInput },
      { ...imageWithAriaFlagBuilderInput, required: true },
    ],
  },
)
