import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'

import { BLOCK_NAMES } from '@/legacy/blocks/blockNames'
import { builderInputsBlockWithBackgroundHex } from '@/legacy/blocks/utils'
import { accordionBuilderInputs } from '@/legacy/components/Accordion/constants'

Builder.registerComponent(
  dynamic<never>(() => import('.').then((mod) => mod.AccordionBlock)),
  {
    name: BLOCK_NAMES.accordion,
    inputs: [
      ...builderInputsBlockWithBackgroundHex({ withPosition: false }),
      ...accordionBuilderInputs,
    ],
  },
)
