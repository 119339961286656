import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'

import { BLOCK_NAMES } from '@/legacy/blocks/blockNames'
import { containerSpaceOutBuilderInput } from '@/legacy/components/Container'

import { titleBuilderInput } from '../constants'

const tabWithListCtaInputs = [
  {
    name: 'label',
    type: 'string',
    friendlyName: 'Label',
  },
  {
    name: 'url',
    type: 'string',
    friendlyName: 'URL',
  },
]

const tabItemInputs = [
  {
    name: 'label',
    type: 'string',
    friendlyName: 'Label',
  },
  {
    name: 'icon',
    type: 'file',
    friendlyName: 'Icon',
    required: true,
    allowedFileTypes: ['svg', 'png', 'jpg', 'jpeg'],
  },
]

const tabConfigInputs = [
  {
    name: 'label',
    type: 'string',
    friendlyName: 'Label',
  },
  {
    name: 'items',
    type: 'list',
    subFields: [...tabItemInputs],
  },
]

Builder.registerComponent(
  dynamic<never>(() => import('.').then((mod) => mod.TabWithListBlock)),
  {
    name: BLOCK_NAMES.tabWithList,
    inputs: [
      {
        ...titleBuilderInput,
      },
      {
        ...containerSpaceOutBuilderInput,
        name: 'space',
      },
      {
        name: 'cta',
        type: 'object',
        friendlyName: 'Call To Action',
        subFields: tabWithListCtaInputs,
      },
      {
        name: 'tabs',
        type: 'list',
        helperText: 'Recomenda-se que utilize até 3 abas.',
        subFields: tabConfigInputs,
      },
    ],
  },
)
