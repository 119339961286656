import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'

import { BLOCK_NAMES } from '@/legacy/blocks/blockNames'
import { buttonAriaLabelBuilderInput, hasButtonBuilderInputs } from '@/legacy/blocks/constants'
import { builderInputsBlockWithBackgroundHex } from '@/legacy/blocks/utils'
import { buttonModifierBuilderInput } from '@/legacy/components/Button/constants.register'
import { midiaBuilderInput } from '@/legacy/components/Media/constants.register'

Builder.registerComponent(
  dynamic<never>(() => import('.').then((mod) => mod.HeroProductPremiumBlock)),
  {
    name: BLOCK_NAMES.premium.heroProductPremium,
    inputs: [
      ...builderInputsBlockWithBackgroundHex({ withPosition: false, isHero: true }),
      {
        name: 'subtitleWeight',
        friendlyName: 'Subtítulo - Peso',
        type: 'string',
        defaultValue: 'regular',
        enum: ['regular', 'bold'],
      },
      midiaBuilderInput,
      ...hasButtonBuilderInputs,
      {
        ...buttonModifierBuilderInput,
        name: 'buttonModifier',
        showIf: (options) => options.get('hasButton'),
      },
      { ...buttonAriaLabelBuilderInput, showIf: (options) => options.get('hasButton') },
      {
        name: 'buttonRounded',
        type: 'boolean',
        friendlyName: 'Botão - Arredondado',
        defaultValue: false,
        showIf: (options) => options.get('hasButton'),
      },
    ],
  },
)
