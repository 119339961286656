import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'

Builder.registerComponent(
  dynamic<never>(() => import('.').then((mod) => mod.default)),
  {
    name: 'DISCOUNT_COUNTER',
    inputs: [
      {
        name: 'image',
        type: 'file',
        friendlyName: 'Imagem',
        allowedFileTypes: ['svg', 'png', 'gif', 'jpg', 'jpeg'],
        helperText: '1752px de largura e 470px de altura',
      },
      {
        name: 'api',
        type: 'string',
        friendlyName: 'URL da API de dados',
      },
      {
        name: 'fullScreen',
        type: 'boolean',
        friendlyName: 'Full Screen',
      },
      {
        name: 'updateInterval',
        type: 'number',
        friendlyName: 'Intervalo de atualização',
      },
      {
        name: 'themeColor',
        type: 'color',
        friendlyName: 'Cor primária',
      },
    ],
  },
)
