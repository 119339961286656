import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'

import { BLOCK_NAMES } from '@/legacy/blocks/blockNames'
import { builderInputsBlockWithBackgroundHex } from '@/legacy/blocks/utils'
import { disclaimerBuilderInputs } from '@/legacy/components/Disclaimer/constants.register'

Builder.registerComponent(
  dynamic<never>(() => import('.').then((mod) => mod.DisclaimerBlock)),
  {
    name: BLOCK_NAMES.disclaimer,
    inputs: [
      ...builderInputsBlockWithBackgroundHex({
        withPosition: false,
        withFontSize: false,
        withTitle: false,
        withSubtitle: false,
      }),
      ...disclaimerBuilderInputs,
    ],
  },
)
