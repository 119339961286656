import { Builder } from '@builder.io/sdk'
import dynamic from 'next/dynamic'

import { BLOCK_NAMES } from '@/legacy/blocks/blockNames'

import { builderInputsBlockWithBackgroundHex } from '../utils'

import { countdownBuilderInputs } from './constants'

Builder.registerComponent(
  dynamic<never>(() => import('.').then((mod) => mod.CountdownBlock)),
  {
    name: BLOCK_NAMES.countdown,
    inputs: [
      ...builderInputsBlockWithBackgroundHex({ withPosition: false }),

      ...countdownBuilderInputs,
    ],
  },
)
