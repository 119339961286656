import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'

import { BLOCK_NAMES } from '@/legacy/blocks/blockNames'
import { idBuilderInput } from '@/legacy/blocks/constants'
import { bannerBuilderInputs } from '@/legacy/components/Banner'
import {
  containerBackgroundColorBuilderInput,
  containerBackgroundColors,
  containerSpaceOutBuilderInput,
} from '@/legacy/components/Container'

Builder.registerComponent(
  dynamic<never>(() => import('.').then((mod) => mod.BannerBlock)),
  {
    name: BLOCK_NAMES.banner,
    inputs: [
      {
        ...containerBackgroundColorBuilderInput,
        name: 'background',
        enum: containerBackgroundColors,
      },
      {
        ...containerSpaceOutBuilderInput,
        name: 'space',
        required: false,
      },
      ...bannerBuilderInputs,
      {
        ...idBuilderInput,
      },
    ],
  },
)
