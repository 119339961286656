import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'

import { SIMULATION_RESULT } from '@/legacy/components/FgtsLoanSimulator/core/utils'

import { BLOCK_NAMES } from '../blockNames'
import { builderInputsBlock } from '../utils'

Builder.registerComponent(
  dynamic<never>(() => import('.').then((mod) => mod.FgtsLoanSimulatorBlock)),
  {
    name: BLOCK_NAMES.loan.fgts,
    inputs: [
      ...builderInputsBlock({
        withPosition: false,
        withTitle: false,
        withSubtitle: false,
        withFontSize: false,
        withContainerSpaceIn: true,
        withContainerSpaceOut: true,
        hasAdvancedOptions: true,
      }),
      {
        name: 'url',
        type: 'string',
        friendlyName: 'URL do CTA',
        defaultValue: SIMULATION_RESULT.cta,
      },
    ],
  },
)
