import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'

import { BLOCK_NAMES } from '@/legacy/blocks/blockNames'

import { containerSpaceOutBuilderInput } from '@/legacy/components/Container'

import { imageBuilderInput } from '@/legacy/components/Media/constants.register'

import { heroTitleBuilderInput } from '../constants'

Builder.registerComponent(
  dynamic<never>(() => import('.').then((mod) => mod.SectionAppInstallBlock)),
  {
    name: BLOCK_NAMES.sectionAppInstall,
    inputs: [
      {
        ...heroTitleBuilderInput,
        required: true,
      },

      {
        name: 'labelApp',
        type: 'string',
        friendlyName: 'Label Apps',
      },
      {
        name: 'labelCode',
        type: 'string',
        friendlyName: 'Label QR Code',
      },
      {
        ...imageBuilderInput,
        friendlyName: 'Utilize imagens múltiplas da proporção 480x548',
      },
      {
        ...containerSpaceOutBuilderInput,
        required: false,
        name: 'space',
      },
    ],
  },
)
