import { Builder } from '@builder.io/react'
import type { Input } from '@builder.io/sdk'
import dynamic from 'next/dynamic'

import { BLOCK_NAMES } from '@/legacy/blocks/blockNames'

import { builderInputsBlockWithBackgroundHex } from '@/legacy/blocks/utils'
import { imageBuilderInput } from '@/legacy/components/Media/constants.register'

const ctaInput: Input = {
  name: 'cta',
  type: 'object',
  friendlyName: 'CTA',
  defaultValue: {
    label: 'Texto botão link...',
    link: 'https://www.serasa.com.br/entrar',
  },
  subFields: [
    {
      name: 'label',
      type: 'string',
      friendlyName: 'Texto',
      required: true,
    },
    {
      name: 'link',
      friendlyName: 'Link',
      type: 'string',
      required: true,
    },
  ],
}

const eventInput: Input = {
  name: 'event',
  friendlyName: 'Evento GA',
  type: 'object',
  subFields: [
    {
      name: 'itemId',
      friendlyName: 'Custom item_id',
      type: 'string',
    },
    {
      name: 'item_text',
      friendlyName: 'Custom item_text',
      type: 'string',
    },
  ],
}

Builder.registerComponent(
  dynamic<never>(() => import('.').then((mod) => mod.ArticlesGalleryBlock)),
  {
    name: BLOCK_NAMES.premium.articlesGallery,
    inputs: [
      ...builderInputsBlockWithBackgroundHex({ withPosition: false }),
      {
        name: 'cards',
        type: 'list',
        subFields: [
          {
            name: 'content',
            friendlyName: 'Item',
            defaultValue: 'Descrição do item...',
            type: 'string',
          },
          ctaInput,
          imageBuilderInput,
          eventInput,
        ],
      },
    ],
  },
)
