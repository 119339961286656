import {
  buttonAriaLabelBuilderInput,
  colorModifierBuilderInput,
  heroSubtitleBuilderInput,
  heroTitleBuilderInput,
  titleBuilderInput,
} from '@/legacy/blocks/constants'

import { imageWithAriaFlagBuilderInput } from '@/legacy/components/Media/constants.register'

const heroInput = {
  name: 'hero',
  type: 'object',
  friendlyName: 'Hero',
  required: true,
  subFields: [
    {
      ...heroTitleBuilderInput,
      required: true,
    },
    {
      ...heroSubtitleBuilderInput,
      required: true,
    },
    colorModifierBuilderInput,
  ],
}

const ctaButtonInput = {
  name: 'button',
  type: 'object',
  friendlyName: 'Botão',
  subFields: [
    {
      name: 'label',
      type: 'string',
      friendlyName: 'Texto',
      required: true,
    },
    {
      name: 'link',
      type: 'string',
      friendlyName: 'Link',
      required: true,
    },
    {
      ...buttonAriaLabelBuilderInput,
    },
    {
      name: 'rounded',
      type: 'boolean',
      friendlyName: 'Botão Arredondado',
      defaultValue: false,
    },
  ],
}

const ctaInput = {
  name: 'cta',
  type: 'object',
  friendlyName: 'Call To Action',
  subFields: [
    {
      ...titleBuilderInput,
      required: true,
      type: 'richText',
    },
    ctaButtonInput,
  ],
}

export const heroWithCtaInputs = [
  heroInput,
  ctaInput,
  { ...imageWithAriaFlagBuilderInput, required: true },
]
