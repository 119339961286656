import './Accordion/register'
import './Ads/register'
import './Banner/register'
import './Cta/register'
import './Custom/register'
import './CustomForm/register'
import './ChatbotWhatsapp/register'
import './Hero/register'
import './HeroProduct/register'
import './List/register'
import './Media/register'
import './Text/register'
import './Disclaimer/register'
import './ProductCarousel/register'
import './HeroWithCta/register'
import './BlackFriday/register'
import './BigNumbers/register'
import './TabWithList/register'
import './AnnouncementBar/register'
import './Countdown/register'
import './Table/register'
import './LoanSimulator/register'
import './SharingButtons/register'
import './FgtsLoanSimulator/register'
import './DiscountCounter/register'
import './HandOnTheWheel/register'
import './PartnerSearchList/register'
import './BuilderText/register'
import './ProductSolutionCarousel/register'
import './SectionAppInstall/register'
import './Premium/ArticlesGallery/register'
import './Premium/DisclaimerPremium/register'
import './Premium/HeroProductPremium/register'
import './Premium/List/register'
import './Premium/MediaWithForms/register'
import './Premium/MiddleButton/register'
import './Premium/Steps/register'
