import type { Input } from '@builder.io/sdk'

import { heroSubtitleBuilderInput, heroTitleBuilderInput } from '../constants'

export const settings: Input = {
  name: 'settingsLink',
  friendlyName: 'Configurações Link',
  type: 'object',
  advanced: true,
  defaultValue: {
    label: 'acesse todos os parceiros',
    link: 'https://www.serasa.com.br/limpa-nome-online/parceiros/',
  },
  subFields: [
    {
      name: 'link',
      type: 'string',
      friendlyName: 'URL',
      helperText: 'Href link da pagina sem resultado de parceiros',
      required: true,
    },
    {
      name: 'label',
      type: 'string',
      friendlyName: 'Label',
      helperText: 'Label do link da pagina sem resultado de parceiros',
      required: true,
    },
  ],
}
const buttonConfig: Input[] = [
  {
    name: 'cardButtonLabel',
    type: 'string',
    friendlyName: 'Label CTAs',
    helperText: 'ex: Negociar dívida. Aplicará para todos os CTAs dos parceiros',
    defaultValue: 'Negociar dívida',
  },
  {
    name: 'concatenation',
    type: 'string',
    friendlyName: 'Concatenação para aria label - Descritivo pro leitor de tela',
    helperText: 'ex: Negociar dívida `com` {nome parceiro} ',
    defaultValue: 'com',
  },
]

export const settingsText: Input[] = [
  {
    ...heroTitleBuilderInput,
    defaultValue: { text: 'Titulo da Seção', tag: 'h2' },
    required: true,
  },
  {
    ...heroSubtitleBuilderInput,
    defaultValue: { text: 'Subtitulo da Seção', tag: 'p' },
    required: true,
  },
  ...buttonConfig,
]
