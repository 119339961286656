import { imageWithAriaFlagBuilderInput } from '../Media/constants.register'

export const disclaimerBuilderInputs = [
  {
    ...imageWithAriaFlagBuilderInput,
    friendlyName: 'Ícone',
    showIf: undefined,
  },
  {
    name: 'content',
    type: 'richText',
    friendlyName: 'Conteúdo',
    required: true,
  },
  {
    name: 'hasSmallFontSize',
    type: 'boolean',
    friendlyName: 'Diminuir Fonte do Conteúdo',
    required: false,
  },
  {
    name: 'containerSize',
    type: 'string',
    defaultValue: 'md',
    friendlyName: 'Largura Máxima do Conteúdo',
    enum: [
      { label: 'Médio', value: 'md' },
      { label: 'Grande', value: 'lg' },
      { label: 'Extra Grande', value: 'xg' },
    ],
  },
]
