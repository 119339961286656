import { Builder } from '@builder.io/react'
import type { Input } from '@builder.io/sdk'
import dynamic from 'next/dynamic'

import { BLOCK_NAMES } from '@/legacy/blocks/blockNames'
import { titleBuilderInput } from '@/legacy/blocks/constants'
import { builderInputsBlock } from '@/legacy/blocks/utils'
import { midiaImageBuilderInput } from '@/legacy/components/Media/constants.register'

import { listLayoutBuilderInput, LIST_LAYOUT } from './constants'

const newsItemInputs: Input[] = [
  {
    ...titleBuilderInput,
    required: true,
    defaultValue: {
      text: 'Título',
      tag: 'h4',
    },
  },
  {
    name: 'description',
    type: 'richText',
    required: true,
    friendlyName: 'Descrição',
  },
  {
    name: 'button',
    type: 'object',
    friendlyName: 'Botão',
    subFields: [
      {
        name: 'label',
        type: 'string',
        friendlyName: 'Texto',
      },
      {
        name: 'action',
        type: 'object',
        subFields: [
          {
            name: 'value',
            type: 'string',
            friendlyName: 'Link URL',
          },
        ],
      },
    ],
  },
  { ...midiaImageBuilderInput },
]

Builder.registerComponent(
  dynamic(() => import('.').then((mod) => mod.ListBlock) as never),
  {
    name: BLOCK_NAMES.list.news,
    inputs: [
      ...builderInputsBlock(),
      {
        ...listLayoutBuilderInput,
        defaultValue: LIST_LAYOUT.news,
      },
      {
        name: 'items',
        type: 'list',
        subFields: [...newsItemInputs],
      },
    ],
  },
)
