import { Builder } from '@builder.io/react'
import type { Input } from '@builder.io/sdk'
import dynamic from 'next/dynamic'

import { BLOCK_NAMES } from '@/legacy/blocks/blockNames'
import {
  colorModifierBuilderInput,
  hasButtonBuilderInput,
  positionBuilderInput,
  subtitleBuilderInput,
  titleBuilderInput,
} from '@/legacy/blocks/constants'
import { builderInputsBlockWithBackgroundHex } from '@/legacy/blocks/utils'
import { buttonModifierBuilderInput } from '@/legacy/components/Button/constants.register'
import { midiaBuilderInput } from '@/legacy/components/Media/constants.register'

const contentValidator = `;(function (options) {
  const MAX_LENGTH = 540
  const content = options.get('content')
  const textWithoutSpecialChars = content.replace(/(<([^>]+)>)/gi, '')

  if (textWithoutSpecialChars.length > MAX_LENGTH) {
    alert('Conteúdo deve ter entre 65 e 540 Caracteres.')
  }

})(options)`

const categoryItemInputs: Input[] = [
  { ...titleBuilderInput },
  { ...subtitleBuilderInput },
  { ...colorModifierBuilderInput },
  {
    name: 'content',
    type: 'richText',
    friendlyName: 'Conteúdo',
    required: true,
    helperText: 'Conteúdo deve ter entre 65 e 540 Caracteres.',
    onChange: contentValidator,
  },

  {
    ...positionBuilderInput,
    enum: [
      { value: 'left', label: 'Esquerda' },
      { value: 'right', label: 'Direita' },
    ],
  },

  { ...hasButtonBuilderInput },
  {
    name: 'button',
    type: 'object',
    friendlyName: 'Botão',
    defaultValue: {
      label: 'Texto botão link...',
      type: 'solid',
      action: {
        type: 'link',
        value: 'https://www.serasa.com.br/entrar',
      },
    },
    subFields: [
      {
        name: 'label',
        type: 'string',
        friendlyName: 'Texto',
        required: true,
      },
      {
        ...buttonModifierBuilderInput,
        name: 'type',
        required: true,
      },
      {
        name: 'rounded',
        type: 'boolean',
        friendlyName: 'Botão arredondado',
        defaultValue: false,
      },
      {
        name: 'action',
        type: 'object',
        required: true,
        subFields: [
          {
            name: 'type',
            type: 'string',
            friendlyName: 'Ação customizada',
            defaultValue: 'link',
            enum: [
              { value: 'sem ação', label: 'Sem ação' },
              { value: 'link', label: 'Link' },
              { value: 'scroll', label: 'Rolagem' },
            ],
            required: true,
          },
          {
            name: 'value',
            type: 'string',
            friendlyName: 'Link URL/ID Elemento (Scroll)',
            required: true,
          },
        ],
      },
    ],
  },

  {
    ...midiaBuilderInput,
    subFields: [
      ...(midiaBuilderInput.subFields ? midiaBuilderInput.subFields : []),
      {
        name: 'imageSize',
        type: 'string',
        defaultValue: 'md',
        friendlyName: 'Tamanho da imagem',
        enum: [
          { label: 'Pequena', value: 'sm' },
          { label: 'Média', value: 'md' },
          { label: 'Grande', value: 'lg' },
          { label: 'Extra Grande', value: 'xg' },
        ],
      },
    ],
  },
]

Builder.registerComponent(
  dynamic<never>(() => import('.').then((mod) => mod.MediaCategoryBlock)),
  {
    name: BLOCK_NAMES.media.category,
    inputs: [
      ...builderInputsBlockWithBackgroundHex({ withContainerSpaceIn: true }),

      {
        name: 'containerSize',
        type: 'string',
        defaultValue: 'md',
        friendlyName: 'Largura Máxima do Conteúdo',
        enum: [
          { label: 'Médio', value: 'md' },
          { label: 'Grande', value: 'lg' },
          { label: 'Extra Grande', value: 'xg' },
        ],
      },
      {
        name: 'carouselController',
        type: 'string',
        defaultValue: 'default',
        friendlyName: 'Tipo de controlador do carousel',
        enum: [
          { label: 'Default', value: 'default' },
          { label: 'Flutuante', value: 'floating' },
        ],
      },
      {
        name: 'carouselControllerColor',
        type: 'string',
        defaultValue: 'primary',
        friendlyName: 'Cor do controlador do carousel',
        enum: [
          { label: 'Primária', value: 'primary' },
          { label: 'Clara', value: 'snow' },
        ],
      },
      {
        name: 'items',
        type: 'list',
        subFields: [...categoryItemInputs],
      },
    ],
  },
)
