import { Builder } from '@builder.io/react'

import dynamic from 'next/dynamic'

import { BLOCK_NAMES } from '@/legacy/blocks/blockNames'
import {
  buttonAriaLabelBuilderInput,
  buttonCustomGALabelBuilderInput,
  hasButtonBuilderInput,
} from '@/legacy/blocks/constants'
import { builderInputsBlock } from '@/legacy/blocks/utils'
import { buttonModifierBuilderInput } from '@/legacy/components/Button/constants.register'

Builder.registerComponent(
  dynamic<never>(() => import('.').then((mod) => mod.TextBlock)),
  {
    name: BLOCK_NAMES.textBlock,
    inputs: [
      ...builderInputsBlock({ withBackground: false, withContainerSpaceIn: true }),
      {
        name: 'content',
        type: 'richText',
        friendlyName: 'Conteúdo',
      },
      hasButtonBuilderInput,
      {
        name: 'button',
        type: 'object',
        friendlyName: 'Botão Link',
        showIf: (options) => options.get('hasButton'),
        defaultValue: {
          label: 'Texto botão link...',
          link: 'https://www.serasa.com.br/entrar',
        },
        subFields: [
          {
            name: 'label',
            type: 'string',
            friendlyName: 'Texto',
          },
          {
            name: 'link',
            type: 'string',
          },
          {
            ...buttonAriaLabelBuilderInput,
            name: 'ariaLabel',
          },
          {
            ...buttonCustomGALabelBuilderInput,
            name: 'customGALabel',
          },
          buttonModifierBuilderInput,
        ],
      },
    ],
  },
)
