import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'

import { BLOCK_NAMES } from '@/legacy/blocks/blockNames'
import { hasButtonBuilderInputs, titleBuilderInput } from '@/legacy/blocks/constants'
import { builderInputsBlock } from '@/legacy/blocks/utils'
import { midiaImageBuilderInput } from '@/legacy/components/Media/constants.register'

import { LIST_LAYOUT, listLayoutBuilderInput } from './constants'

const blogItemInputs = [
  {
    ...titleBuilderInput,
    required: true,
    defaultValue: {
      text: 'Título',
      tag: 'h4',
    },
  },
  {
    name: 'description',
    type: 'richText',
    friendlyName: 'Descrição',
    required: true,
    defaultValue: 'Informe aqui uma descrição...',
  },
  {
    name: 'link',
    type: 'string',
    friendlyName: 'Link URL',
  },
  { ...midiaImageBuilderInput },
]

Builder.registerComponent(
  dynamic(() => import('.').then((mod) => mod.ListBlock) as never),
  {
    name: BLOCK_NAMES.list.blog,
    inputs: [
      ...builderInputsBlock(),
      ...hasButtonBuilderInputs,
      {
        name: 'itemsPerRow',
        type: 'number',
        friendlyName: 'Colunas',
        defaultValue: 4,
        hideFromUI: true,
      },
      {
        ...listLayoutBuilderInput,
        defaultValue: LIST_LAYOUT.blog,
      },
      {
        name: 'items',
        type: 'list',
        subFields: [...blogItemInputs],
      },
    ],
  },
)
